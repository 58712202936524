<template>
  <div :class="['box', `box--align-${align}`]">
    <div class="icon">
      <slot />
    </div>
    <typography :bold="bold" :type="textType" :align="align" class="text">
      {{ text }}
    </typography>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';

export default {
  components: {
    Typography
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    bold: {
      type: Boolean,
      default: false
    },
    textType: {
      type: String,
      default: 'small-p'
    },
    align: {
      type: String,
      default: 'center'
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.box {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  width: rem(140px);
  margin: rem(10px 0);
  max-width: 100%;
}

.icon {
  margin: rem(0 auto 10px auto);
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: scale(0.7);

  .box--align-left & {
    text-align: left;
    justify-content: flex-start;
    margin: rem(0 0 10px 10px);

    @include md {
      margin: rem(0 0 10px 20px);
    }
  }

  @include md {
    transform: none;
  }
}
</style>
