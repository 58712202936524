<template>
  <hr :class="['hr', `hr--${color}`]">
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white',
      validator: value => {
        return ['grey', 'white'].includes(value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.hr {
  border: 0;

  &--white {
    opacity: 0.2;
    border-top: 1px solid $white;
  }

  &--grey {
    border-top: 1px solid #e4e4e4;
  }
}
</style>
