<template>
  <landing class="landing" :hero-image="heroImage">
    <div class="content">
      <v-layout class="back-link-box" mb-5>
        <v-flex>
          <a href="#" class="back-link" @click.prevent="onBack">
            <back-icon />
            <typography class="back-text" type="tag" no-margin>Back</typography>
          </a>
        </v-flex>
      </v-layout>
      <typography type="h3">To avoid delays...</typography>
      <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
        <v-flex md10>
          <typography class="text" light>
            Please double check that you are an authorised representative of a
            GST registered business or a NZ registered charity — we will be asking
            you to set up a direct debit and/or undergo a credit check.
          </typography>
          <horizontal-line v-if="$vuetify.breakpoint.xsOnly" class="line" color="grey" />
        </v-flex>
      </v-layout>

      <div class="requirements">
        <typography
          :type="$vuetify.breakpoint.xsOnly ? 'small-p' : 'body'"
          bold
        >2 things you’ll need before starting</typography>
        <div class="icons">
          <icon-and-text
            :align="$vuetify.breakpoint.xsOnly ? 'center' : 'left'"
            text="Your business’ bank account details"
          >
            <bank-icon />
          </icon-and-text>
          <icon-and-text
            :align="$vuetify.breakpoint.xsOnly ? 'center' : 'left'"
            text="Company director’s driver licence"
          >
            <license-icon />
          </icon-and-text>
        </div>
        <typography type="small-note">
          We may request further information once your application has been
          assessed.
        </typography>
      </div>
      <v-layout :justify-center="$vuetify.breakpoint.smAndDown" mb-5>
        <v-flex xs10 md12>
          <round-button @click="onContinue">Let's go</round-button>
        </v-flex>
      </v-layout>
    </div>
  </landing>
</template>

<script>
import Landing from '@/components/templates/Landing.vue';
import Typography from '@/components/atoms/Typography.vue';
import LicenseIcon from '@/assets/icons/car-license.svg';
import BankIcon from '@/assets/icons/bank-details.svg';
import RoundButton from '@/components/atoms/RoundButton.vue';
import HorizontalLine from '@/components/atoms/HorizontalLine.vue';
import IconAndText from '@/components/molecules/IconAndText.vue';
import BackIcon from '@/assets/icons/back.svg';
import { FORM, LANDING } from '@/constants/routes';
import { PERSISTENT_HERO_IMAGE } from '@/constants/form';

export default {
  components: {
    HorizontalLine,
    IconAndText,
    Landing,
    Typography,
    BackIcon,
    LicenseIcon,
    BankIcon,
    RoundButton
  },
  data() {
    return {
      heroImage: PERSISTENT_HERO_IMAGE
    };
  },
  methods: {
    onContinue() {
      this.$router.push(FORM);
    },
    onBack() {
      this.$router.push(LANDING);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.landing {
  text-align: center;

  @include sm {
    text-align: left;
  }
}

.back-link-box {
  position: absolute;
  top: rem(20px);
  left: rem(20px);

  @include sm {
    position: relative;
    top: auto;
    left: auto;
  }
}

.back-link {
  display: inline-flex;
  align-items: center;
  color: $charcoal;

  .back-text {
    margin-left: rem(10px);
  }
}

.content {
  padding: rem(50px 0);
}

.text {
  max-width: 100%;
  margin: rem(0 0 40px 0);
}

.requirements {
  margin: rem(40px 0 40px 0);

  @include sm {
    margin: rem(20px 0 60px 0);
  }
}

.icons {
  display: flex;
  justify-content: center;
  margin-bottom: rem(30px);

  @include sm {
    justify-content: flex-start;
  }
}
</style>
